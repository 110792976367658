<template>
  <div>
    <v-simple-table fixed-header height="calc(100vh - 145px)" class="table-padding-2">
      <template v-slot:default>
        <thead class="v-data-table-header">
          <tr>
            <th role="columnheader" class="text-center">
              <SelectMaterialWarehouse :label="$t('labels.warehouse_1')" :placeholder="$t('labels.warehouse_1')"
                name="id_material_warehouse" sort-name="material_warehouse_code" has-sort :sorting="filters.sort_by"
                @onFilter="onFilterChange" @onSort="onSortChange" />
            </th>
            <th role="columnheader" style="min-width: 100px">
              <InputFilter :default-value="filters.code" :label="$t('labels.barcode')"
                :placeholder="$t('labels.barcode')" name="code" sort-name="code"
                has-sort :sorting="filters.sort_by" @onFilter="onFilterChange" @onSort="onSortChange" />
            </th>
            <th role="columnheader" style="min-width: 100px">
              <InputFilter :default-value="filters.keywords" :label="$t('labels.uid')" :placeholder="$t('labels.uid')"
                name="sku_uid" sort-name="sku_uid" has-sort :sorting="filters.sort_by" @onFilter="onFilterChange"
                @onSort="onSortChange" />
            </th>
            <th role="columnheader" style="min-width: 100px">
              <SelectFilter :options="yesNoOptions" :label="$t('labels.available')"
                :placeholder="$t('labels.available')" name="available" sort-name="available" has-sort
                :sorting="filters.sort_by" @onFilter="onFilterChange" @onSort="onSortChange" />
            </th>
            <th role="columnheader" style="min-width: 100px">
              <SelectFilter :options="statusOption" :label="$t('labels.status')" :placeholder="$t('labels.status')"
                name="status" sort-name="status" has-sort :sorting="filters.sort_by" @onFilter="onFilterChange"
                @onSort="onSortChange" />
            </th>
            <th role="columnheader" style="min-width: 100px">
              <DateRangeFilter :label="$t('labels.import')" :placeholder="$t('labels.import')" name="receipted_at"
                sort-name="receipted_at" has-sort :sorting="filters.sort_by" @onFilter="onFilterChange"
                @onSort="onSortChange" />
            </th>
          </tr>
        </thead>

        <tbody>
          <tr v-for="(item, key) in items" :key="`${key}_${item.id}`" class="text-center">
            <td>{{ item.material_warehouse_code }}</td>
            <td>{{ item.code }}</td>
            <td>
              <span v-if="!item.barcode" class="text-decoration-underline primary--text cursor-pointer"
                @click="showHistoryDialog(item)">
                {{ item.sku_uid }}
              </span>
            </td>
            <td>
              <!-- <span v-if="checkPermission(['goods_uid_roll', 'warehouse_admin']) && [1, 2, 3].includes(item.status)"
                class="error--text text-decoration-underline cursor-pointer" @click="showRollUid(item)">
                {{ item.available ? "Yes" : "No" }}
              </span> -->
              <span>{{ item.available ? "Yes" : "No" }}</span>
            </td>
            <td>{{ statusTxt[item.status] }}</td>
            <td>{{ item.receipted_at ? formatDateTime(item.receipted_at) : "" }}</td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>

    <v-row class="pt-3">
      <v-col cols="12" sm="3">
        <div class="d-flex table-footer-count mt-3" v-if="filters.sku || filters.code">
          <div class="mr-2">
            {{ $t("labels.sum") }}: <b>{{ formatNumber(totalItem) }}</b>
          </div>
          <div class="mr-2">
            {{ $t("labels.available") }}:
            <b>{{ formatNumber(totalAvailable) }}</b>
          </div>
        </div>
      </v-col>
      <v-col cols="12" sm="6">
        <v-pagination v-model="page" :length="totalPage" :total-visible="5"></v-pagination>
      </v-col>
      <v-col sm="3">
        <div class="d-flex align-center justify-end">
          <v-btn color="success" @click="exportExcel"
            v-if="checkPermission(['goods_uid_manager']) && (filters.sku || filters.code)"
            style="height: 40px" class="mr-2">
            <v-icon>mdi-download</v-icon>
            {{ $t("labels.excel") }}
          </v-btn>
          <div style="width: 100px">
            <v-text-field dense outlined hide-details v-model.number="page" :label="$t('labels.page')"
              :placeholder="$t('labels.page')"></v-text-field>
          </div>
        </div>
      </v-col>
    </v-row>

    <v-dialog v-model="rollUidDialog" persistent max-width="350px">
      <RollUid v-if="rollUidDialog" :item="updatingItem" @cancel="hideRollUid" @refreshData="getList" />
    </v-dialog>

    <v-dialog v-model="historyDialog" persistent max-width="1024px">
      <UidHistory v-if="historyDialog" :item="updatingItem" @close="hideHistoryDialog" />
    </v-dialog>
  </div>
</template>

<script>
import uid from "@/components/material/mixins/uid";

export default {
  name: "Uid",
  components: {
    SelectMaterialWarehouse: () => import("@/components/table/SelectMaterialWarehouse"),
    DateRangeFilter: () => import("@/components/table/DateRangeFilter"),
    SelectFilter: () => import("@/components/table/SelectFilter"),
    InputFilter: () => import("@/components/table/InputFilter"),
    RollUid: () => import("@/components/material/RollUid"),
    UidHistory: () => import("@/components/material/UidHistory"),
  },
  mixins: [uid],
  watch: {
    filters: {
      handler() {
        this.page = 1;
        this.getList();
      },
      deep: true,
    },
  },
  mounted() {
    this.getList();
  },
};
</script>

<style scoped></style>
